import React, { useState } from 'react'; 
import './Card.css';
import cardIcon from '../../assets/black-card.svg';
import cartIcon from '../../assets/carrito_1.svg';
import cartIconDisabled from '../../assets/carrito_Enough.svg';
import cartIconHover from '../../assets/carrito_2.svg';
import favoriteIconFull from '../../assets/fav_full.svg';
import viewIcon from '../../assets/ver.svg';
import viewIconAlt from '../../assets/no-ver.svg';
import Modal from '../../ui/Modal/Modal';
import designIcon from '../../assets/disenamos.svg';
import { useDispatch } from 'react-redux';
import { getCarrito } from '../../redux/actions';
import favoriteIcon from '../../assets/fav_1.svg';
import axios from 'axios';
import { trackEvent } from '../../utils/fbpPixel';
import { v4 as uuidv4 } from 'uuid';

export default function Card({
  nombre,
  carrito,
  setCarrito,
  price,
  imageUrl,
  item,
  priceOffert,
  favorite = false,
  dolar,
  isHome,
  deleteFav,
  admin,
  carritoLocale,
  isHomeOrDestacada,
  setDataModal,
  setIsOpenModal,
  isOpenModal,
}) {
  const token = localStorage.getItem('token');
  const [isFavorited, setIsFavorited] = useState(deleteFav || false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoverStates, setHoverStates] = useState({
    tooltip: false,
    view: false,
    favorite: false,
    cart: false,
  });
  const dispatch = useDispatch();

  const itemPriceUSD = item.precio_dolar || 0;
  const itemPriceUSDOffert = item.precio_oferta_dolares || 0;

  const precioConIncremento = itemPriceUSD * dolar;
  const precioOfertaIncremento = itemPriceUSDOffert * dolar;

  const descuento = precioConIncremento * 0.2;
  const descuentoOferta = precioOfertaIncremento * 0.2;

  const precioFinal = (precioConIncremento - descuento).toFixed(0);
  const precioOferta = itemPriceUSDOffert
    ? (precioOfertaIncremento - descuentoOferta).toFixed(0)
    : 0;

  const calculateInstallment = () => (precioConIncremento / 6).toFixed(2);

  const porcentajeDiferencia = itemPriceUSDOffert
    ? `${Math.abs(
        ((100 - (itemPriceUSDOffert * 100) / itemPriceUSD)).toFixed(0)
      )}% OFF`
    : '';

  const [installmentPrice, installmentDecimal] = calculateInstallment().split(
    '.'
  );

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const getAnonymousId = () => {
    let anonymousId = localStorage.getItem('anonymous_id');
    if (!anonymousId) {
      anonymousId = uuidv4();
      localStorage.setItem('anonymous_id', anonymousId);
    }
    return anonymousId;
  };

  const handleAddToCart = async () => {
    if (token){
      try {
        await axios.post(
          'https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/agregar-carrito',
          {
            httpMethod: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            queryStringParameters: {},
            pathParameters: {},
            body: JSON.stringify({
              token,
              idproducto: item.idproducto,
              cantidad: 1,
            }),
            isBase64Encoded: false,
        });
        dispatch(getCarrito(token));
        setCarrito(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      // Manejo del carrito en localStorage para usuarios no autenticados
      try {
        const carritoLocal = JSON.parse(localStorage.getItem('carrito-sce-product-manager')) || [];
        const existingItemIndex = carritoLocal.findIndex(
          (prod) => String(prod.idproducto) === String(item.idproducto)
        );

        if (existingItemIndex !== -1) {
          const currentCantidad = Number(carritoLocal[existingItemIndex].cantidad) || 0;
          carritoLocal[existingItemIndex].cantidad = currentCantidad + 1;
          console.log(`Cantidad incrementada para idproducto ${item.idproducto}:`, carritoLocal[existingItemIndex].cantidad);
        } else {
          carritoLocal.push({
            idproducto: String(item.idproducto),
            nombre: nombre,
            precio: Number(precioFinal),
            cantidad: 1,
            imageUrl: imageUrl,
          });
          console.log('Producto agregado al carrito:', carritoLocal[carritoLocal.length - 1]);
        }

        localStorage.setItem('carrito-sce-product-manager', JSON.stringify(carritoLocal));
        console.log('Carrito después de agregar:', carritoLocal);
        setCarrito(true);
      } catch(error){
        console.error('Error al actualizar el carrito:', error);
      }
    }

    // Tracking con fbq
    trackEvent('AddToCart', {
      content_name: item.nombre,
      content_ids: [String(item.idproducto)],
      content_type: 'product',
      value: Number(precioFinal),
      currency: 'ARS',
    });

      // Leer las cookies _fbp y _fbc
      const fbp = getCookie('_fbp');
      const fbc = getCookie('_fbc');

      // Definir los patrones de formato
      const fbpPattern = /^fb\.\d+\.\d+\.[\w-]+$/;
      const fbcPattern = /^fb\.\d+\.[\w-]+$/;

      // Validar el formato de fbp y fbc
      const isFbpValid = fbp && fbpPattern.test(fbp);
      const isFbcValid = fbc && fbcPattern.test(fbc);

      if (!isFbpValid && !isFbcValid) {
        console.error('Las cookies _fbp y _fbc están ausentes o mal formateadas:', { fbp, fbc });
        // Continuar enviando el evento solo con anonymous_id
        // o decidir no enviar el evento si es crítico tener estos identificadores
      }

      // Generar un event_id único
      const eventId = uuidv4();

      // Obtener o generar anonymous_id
      const anonymousId = getAnonymousId();

      // Construir user_data
      const userData = {};
      if (isFbpValid) userData.fbp = fbp;
      if (isFbcValid) userData.fbc = fbc;
      if (anonymousId) userData.anonymous_id = anonymousId;

      // Enviar evento a la Conversion API de Facebook si hay al menos un identificador
      if (Object.keys(userData).length === 0) {
        console.error('No hay identificadores disponibles para enviar el evento.');
        return;
      }

      try {
        const response = await axios.post('https://sistema.depcsuite.com/api/event-facebook', {
          event_id: eventId, // Incluir event_id
          event_name: 'AddToCart',
          custom_data: {
            content_name: item.nombre,
            content_category: item.categorias, // Asegúrate de que este campo exista
            content_ids: [String(item.idproducto)],
            contents: [
              {
                id: String(item.idproducto),
                quantity: 1,
                item_price: Number(precioFinal),
              },
            ],
            value: Number(precioFinal),
            currency: 'ARS',
          },
          user_data: userData, // Incluir los identificadores disponibles
        });

        if (response.data.success) {
          console.log(response.data.message);
        } else {
          console.error(response.data.message, response.data.error);
        }
      } catch (error) {
        console.error('Error al enviar el evento a Facebook:', error.response?.data || error.message);
      }
    };

  const handleFavoriteToggle = async () => {
    if (!token) return (window.location.href = '/ingresar');

    try {
      await axios.post(
        'https://dk8qbwpywj.execute-api.us-east-1.amazonaws.com/prod/sce-agregar-favoritos',
        {
          token,
          idproducto: item.idproducto,
          isDelete: isFavorited,
        }
      );
      setIsFavorited(!isFavorited);
    } catch (error) {
      console.error(error);
      localStorage.removeItem('token');
      window.location.href = '/ingresar';
    }
  };

  const handleOpenModal = () => {
    if (isHomeOrDestacada) {
      setIsOpenModal(true);
      setDataModal({
        isOpen: isOpenModal,
        setIsOpen: setIsOpenModal,
        item,
        carrito,
        setCarrito,
        precioFinal: Number(
          itemPriceUSDOffert ? precioOferta : precioFinal
        ),
        precioConIncremento,
        porcentaje: porcentajeDiferencia,
        precioTachado: itemPriceUSDOffert && Number(precioFinal),
      });
      trackEvent('ViewContent', {
          content_name: item.nombre,
          content_category: item.categorias,
          content_ids: [String(item.idproducto)],
          content_type: 'product',
          value: Number(precioFinal),
          currency: 'ARS',
      });
    } else {
      setIsModalOpen(true);
    }
  };

  return (
    <div
    className={`relative lg:w-[309px] w-[187px] rounded-[4px] ${
      favorite ? 'h-72' : 'lg:h-[578px] h-[363.25px]'
    }`}
      onMouseEnter={() => setHoverStates({ ...hoverStates, tooltip: true })}
      onMouseLeave={() => setHoverStates({ ...hoverStates, tooltip: false })}
    >
      {porcentajeDiferencia && Number(precioFinal) !== 0 && Number(item.cantidad) !== 0 && (
        <div className="absolute right-0 top-5 bg-[#E40F1B] text-sm rounded-l-md text-white px-3">
          {porcentajeDiferencia}
        </div>
      )}
      <a href={`/producto/${item.slug || ''}-${item.idproducto}`}>
        <img
          src={`https://sistema.depcsuite.com/productos/${imageUrl}`}
          alt="Producto"
          className={`${
            favorite
              ? 'w-60'
              : isHome
              ? 'w-72 lg:mr-3'
              : 'w-[309px]'
          } ${isHome ? 'lg:mr-2' : ''}`}
        />
      </a>
      {(isFavorited || hoverStates.tooltip) && (
        <div
        className={`hidden lg:flex absolute ${
          porcentajeDiferencia ? 'mr-8' : ''
        } items-start w-full justify-center ${
          !favorite ? 'bottom-1/2' : 'bottom-10'
        }`}
        >
          <div
            className={`flex flex-row ${
              admin ? 'gap-4' : 'gap-10'
            } justify-between`}
          >
            <div
              onMouseEnter={() => setHoverStates({ ...hoverStates, view: true })}
              onMouseLeave={() => setHoverStates({ ...hoverStates, view: false })}
            >
              <button
                className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl"
                onClick={handleOpenModal}
              >
                <img
                  src={hoverStates.view ? viewIcon : viewIconAlt}
                  alt="Ver"
                />
              </button>
            </div>
            <div
              onMouseEnter={() =>
                setHoverStates({ ...hoverStates, cart: true })
              }
              onMouseLeave={() =>
                setHoverStates({ ...hoverStates, cart: false })
              }
            >
              {Number(precioFinal) !== 0 && Number(item.cantidad) !== 0 ? (
                <button
                  className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl"
                  onClick={handleAddToCart}
                >
                  <img
                    src={hoverStates.cart ? cartIconHover : cartIcon}
                    alt="Agregar al carrito"
                  />
                </button>
              ) : (
                <div className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl">
                  <img
                    src={cartIconDisabled}
                    alt="Agotado"
                  />
                </div>
              )}
            </div>
            <div
              onMouseEnter={() =>
                setHoverStates({ ...hoverStates, favorite: true })
              }
              onMouseLeave={() =>
                setHoverStates({ ...hoverStates, favorite: false })
              }
            >
              <button
                className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl"
                onClick={handleFavoriteToggle}
              >
                <img
                  src={
                    isFavorited
                      ? favoriteIconFull
                      : hoverStates.favorite
                      ? favoriteIconFull
                      : favoriteIcon
                  }
                  alt="Favorito"
                />
              </button>
            </div>
            {admin && (
              <div className="flex justify-center h-[46px] w-[46px] rounded-md items-center bg-white shadow-xl">
                <a
                  href={`https://sistema.depcsuite.com/producto/productos/${item.idproducto}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={designIcon} alt="Editar" />
                </a>
              </div>
            )}
          </div>
        </div>
      )}
      {!favorite && (
        <div className="flex flex-col text-center px-5">
          <div className="text-sm font-semibold">{nombre}</div>
          <div className="text-sm mt-2">
            {Number(precioFinal) != 0 && Number(item.cantidad) != 0 ? (
              <>
                <div className="font-bold text-xl">
                  {Number(
                    itemPriceUSDOffert ? precioOferta : precioFinal
                  ).toLocaleString('es-AR', {
                    style: 'currency',
                    currency: 'ARS',
                    maximumFractionDigits: 0,
                  })}
                </div>
                <strong className="text-xs">
                  20% OFF con transferencia
                </strong>
                {itemPriceUSDOffert != 0 && (
                  <div className="line-through opacity-90">
                    {Number(precioFinal).toLocaleString('es-AR', {
                      style: 'currency',
                      currency: 'ARS',
                      maximumFractionDigits: 0,
                    })}
                  </div>
                )}
              </>
            ) : (
              <div className="text-sm">AGOTADO</div>
            )}
          </div>
          {Number(precioFinal) != 0 && Number(item.cantidad) !== 0 && (
            <div className="text-sm font-regularQuicksand mt-2">
              {Number(precioConIncremento).toLocaleString('es-AR', {
                style: 'currency',
                currency: 'ARS',
                maximumFractionDigits: 0,
              })}{' '}
              de lista
            </div>
          )}
          {Number(precioFinal) != 0 && Number(item.cantidad) !== 0 && (
            <div className="flex font-mediumQuicksand mt-[11px] items-center justify-center">
              <img src={cardIcon} alt="Tarjeta" className="w-4" />
              <span className="text-[12px] ml-1">6 cuotas de </span>
              <span className="ml-1 text-[11px]">
                {Number(installmentPrice).toLocaleString('es-AR', {
                  style: 'currency',
                  currency: 'ARS',
                  maximumFractionDigits: 0,
                })}
              </span>
              <span className="text-[7px] mt-[4.4px] ml-[2px]">
                {installmentDecimal}
              </span>
            </div>
          )}
        </div>
      )}
      {!isHomeOrDestacada && (
        <Modal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          item={item}
          carrito={carrito}
          setCarrito={setCarrito}
          precioFinal={Number(
            itemPriceUSDOffert ? precioOferta : precioFinal
          )}
          precioTachado={itemPriceUSDOffert && Number(precioFinal)}
          porcentaje={porcentajeDiferencia}
          precioConIncremento={precioConIncremento}
        />
      )}
    </div>
  );
}
